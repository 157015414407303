function Navbar(props){
  const NavbarStyle = {
    margin:0,
    padding:'1em',
    boxSizing:'border-box',
    width:'100%',
    height:'40px',
    borderRadius:'.5rem .5rem 0 0',
    display:'flex',
    background:'white',
    justifyContent:'flex-start',
    gap:'.3rem',
    alignItems:'center'
   };
   const circleStyle = (color) => {
    return {
    margin:0,
    padding:0,
    boxSizing:'border-box',
    width:'15px',
    height:'15px',
    borderRadius:'50%',
    display:'flex',
    background:color,
    border:'0.01rem solid black',
    cursor:'pointer'
    } ;
   }
   
   

  return(
    <div style={NavbarStyle}>
        <div style={circleStyle('#FF5757')} onClick={e=>{props.setter({windColor:'#A9274E',appColor:'#DB4F79'})}}></div>
        <div style={circleStyle('#FFD057')} onClick={e=>{props.setter({windColor:'#294477',appColor:'#3D91C1'})}}></div>
        <div style={circleStyle('#5DC355')} onClick={e=>{props.setter({windColor:'#297052',appColor:'#43BF8B'})}}></div>
        <div></div>
    </div>
  )
}

export default Navbar ;