// import './App.css';
// import CardList from './Components/CardList';
// import Trending from './Components/Trending';


// function App() {
//   return (
//   <div className="App">
//     <GIFsProvider>
//     <Searchbar/>
//     <Trending/>
//     <CardList/>
//     </GIFsProvider>
//   </div>
//   );
// }

// export default App;

import { useState } from "react";
import Navbar from "./Comp/Navbar";


function App()
{  
  const [colorPalate,setPalate] = useState({windColor:'#A9274E',appColor:'#DB4F79'});

  const WindowStyle = {
   margin:0,
   padding:0,
   boxSizing:'border-box',
   width:'700px',
   minHeight:'600px',
   border:'1px solid black',
   borderRadius:'.5rem',
   display:'flex',
   boxShadow:'0 1rem 1rem 0 rgba(0, 0, 0, 0.5)',
   background:colorPalate.windColor,
   flexDirection:'column',
   gap:'1rem'
  };

  const AppStyle = {
  background : colorPalate.appColor,
  display:'grid',
  flexDirection : 'column',
  margin:0,
  padding:0,
  boxSizing:'border-box',
  placeContent:'center',
  minHeight:'100vh'
  };

  return(
    <diV style={AppStyle}>
      <div style={WindowStyle}>
        <Navbar setter={setPalate}/>
      </div>
    </diV>
  );
}

export default App ;